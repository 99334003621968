import { createElement } from '../../../assets/helpers/common';
import { Panels } from '../Panels';
import { getCookie } from '../../../assets/helpers/cookie.js';
import CheckOutIcon from '../../../assets/images/icons/checkout_page_icons/Icon-checkout.svg';
import arrowDownIcon from '../../../assets/images/icons/landing_page/arrow_down.svg';
import LoadIcon from '../../../assets/images/icons/checkout_page_icons/Icon-load.svg';
import PriceIcon from '../../../assets/images/icons/checkout_page_icons/Icon-Price.svg';
import PrintIcon from '../../../assets/images/icons/checkout_page_icons/Icon-print.svg';
import SaveIcon from '../../../assets/images/icons/checkout_page_icons/Icon-save.svg';
import closeSvg from '../../../assets/images/icons/close.svg';
import { showDrawer } from '../../../uiComponents/drawer';
import { loadPhoneCodes } from '../../../assets/phoneCodes/phoneCodes.js';
import { loadStates } from '../../../assets/USStates/states.js';
import { config } from '../../../config/config';
import { isLoggedIn } from '../../../uiComponents/login';

export class CheckoutPanel extends Panels {
  constructor (save, load, priceRequest, print, checkout) {
    super();
    this.save = save;
    this.load = load;
    this.priceRequest = priceRequest;
    this.print = print;
    this.checkout = checkout;
    this.formFor = null;

    this.container = createElement('div', 'checkoutPanelContainer', 'checkoutPanelContainer');
    document.body.appendChild(this.container);
    this.createContent();
  }

  createContent = () => {
    const wrapper = createElement('div', null, 'checkoutPanelWrapper');
    const loadSaveWrapper = createElement('div', null, 'checkoutPanelLoadSaveWrapper');
    const pricePrintWrapper = createElement('div', null, 'checkoutPanelPricePrintWrapper');
    //const checkuotWrapper = createElement('div', null, 'checkout-page-icon-wrapper-full');
    const logicSWrapper = createElement('div', null, 'checkout-page-logics-wrapper');
    const loadWrapper = createElement('div', null, 'checkout-page-icon-wrapper');
    const saveWrapper = createElement('div', null, 'checkout-page-icon-wrapper');
    const priceWrapper = createElement('div', null, 'checkout-page-icon-wrapper');
    const printWrapper = createElement('div', null, 'checkout-page-icon-wrapper');
    const loadImg = createElement('img');
    const saveImg = createElement('img');
    const printImg = createElement('img');
    const priceImg = createElement('img');
    const checkoutImg = createElement('img');
    const loadImgTxt = createElement('div');
    const saveImgTxt = createElement('div');
    const printImgTxt = createElement('div');
    const priceImgTxt = createElement('div');
    const checkoutImgTxt = createElement('div');
    const selectArrowIcon = createElement('img', null, 'checkoutPanelFormSelectArrow');
    selectArrowIcon.src = arrowDownIcon;

    const personalDetailsWrapperBack = createElement('div', 'checkoutPersnalDetailsWrapper', 'checkoutPanelFormWrapperBack');
    const personalDetailsWrapper = createElement('div', null, 'checkoutPanelFormWrapper');
    const personalDetailsFormHeader = createElement('div', null, 'checkoutPanelFormHeader');
    const title = createElement('p', null, 'checkoutPanelFormTitle');
    title.innerHTML = `${this.localization.strings.personalDetailsTitle}:`;
    const personalDetailsFormClose = createElement('div', 'checkoutPanelSaveFormClose', 'checkoutPanelCloseBtn');
    const personalDetailsFormCloseImg = createElement('img');
    personalDetailsFormCloseImg.src = closeSvg;
    const form = createElement('form', null, 'checkoutPanelForm');

    const labelFName = createElement('label', null, 'checkoutPanelLabel', { for: 'first_name' });
    labelFName.innerHTML = `${this.localization.strings.personalDetailsFName}:`;
    const inputFName = createElement(
      'input', null, 'checkoutPanelFormInput', { type: 'text', name: 'first_name', required: true, placeholder: this.localization.strings.personalDetailsFName }
    );

    const labelLName = createElement('label', null, 'checkoutPanelLabel', { for: 'last_name' });
    labelLName.innerHTML = `${this.localization.strings.personalDetailsLName}:`;
    const inputLName = createElement(
      'input', null, 'checkoutPanelFormInput', { type: 'text', name: 'last_name', required: true, placeholder: this.localization.strings.personalDetailsLName }
    );

    const labelEmail = createElement('label', null, 'checkoutPanelLabel', { for: 'email' });
    labelEmail.innerHTML = `${this.localization.strings.personalDetailsEmail}:`;
    const inputEmail = createElement(
      'input', null, 'checkoutPanelFormInput', { type: 'email', name: 'email', required: true, placeholder: this.localization.strings.personalDetailsEmail }
    );

    const labelState = createElement('label', null, 'checkoutPanelLabel', { for: 'state' });
    labelState.innerHTML = `${this.localization.strings.USStateSelect}:`;
    const stateSelectContainer = createElement('div', null, 'checkoutPanelFormSelectContainer');
    const stateSelect = createElement(
      'select', null, 'checkoutPanelFormSelect', { name: 'state' }
    );
    stateSelect.innerHTML = loadStates();

    const labelPhone = createElement('label', null, 'checkoutPanelLabel', { for: 'phone' });
    const phoneWrapper = createElement('div', null, 'checkoutPanelPhoneWrapper');
    // const phoneCodeSelect = createElement('select', null, 'checkoutPanelFormInput', { name: 'phone_code' });
    const phoneCodeSelectContainer = createElement('div', null, 'checkoutPanelFormSelectContainer');
    const phoneCodeSelect = createElement(
      'select', null, 'checkoutPanelFormSelect', { name: 'phone_code' }
    );
    phoneCodeSelect.innerHTML = loadPhoneCodes();
    labelPhone.innerHTML = `${this.localization.strings.personalDetailsPhone}:`;
    const inputPhone = createElement(
      'input', null, 'checkoutPanelFormInput', { type: 'text', name: 'phone', required: true, placeholder: this.localization.strings.personalDetailsPhone }
    );
    stateSelect.onchange = () => { if (stateSelect.value !== 'Other') phoneCodeSelect.value = '+1'; };

    const inputSubmit = createElement('input', null, 'checkoutPanelFormSubmit', { type: 'submit' });
    form.addEventListener('submit', this.onFormSubmit);
    personalDetailsFormClose.onclick = () => this.setFormVisibility('checkoutPersnalDetailsWrapper', false);

    personalDetailsWrapper.appendChild(personalDetailsFormHeader);
    personalDetailsFormHeader.appendChild(title);
    personalDetailsFormHeader.appendChild(personalDetailsFormClose);
    personalDetailsFormClose.appendChild(personalDetailsFormCloseImg);
    personalDetailsWrapper.appendChild(form);
    personalDetailsWrapperBack.appendChild(personalDetailsWrapper);

    form.appendChild(labelFName);
    form.appendChild(inputFName);
    form.appendChild(labelLName);
    form.appendChild(inputLName);
    form.appendChild(labelEmail);
    form.appendChild(inputEmail);
    form.appendChild(labelState);
    stateSelectContainer.appendChild(stateSelect);
    stateSelectContainer.appendChild(selectArrowIcon.cloneNode(true));
    form.appendChild(stateSelectContainer);
    form.appendChild(labelPhone);
    form.appendChild(phoneWrapper);
    phoneCodeSelectContainer.appendChild(phoneCodeSelect);
    phoneCodeSelectContainer.appendChild(selectArrowIcon.cloneNode(true));
    phoneWrapper.appendChild(phoneCodeSelectContainer);
    phoneWrapper.appendChild(inputPhone);
    form.appendChild(inputSubmit);

    const saveFormWrapperBack = createElement('div', 'checkoutSaveFormWrapper', 'checkoutPanelFormWrapperBack');
    const saveFormWrapper = createElement('div', null, 'checkoutPanelFormWrapper');
    const saveFormHeader = createElement('div', null, 'checkoutPanelFormHeader');
    const saveFormTitle = createElement('p', null, 'checkoutPanelFormTitle');
    saveFormTitle.innerHTML = `${this.localization.strings.saveFormTitle}:`;
    const saveFormClose = createElement('div', 'checkoutPanelSaveFormClose', 'checkoutPanelCloseBtn');
    const saveFormCloseImg = createElement('img');
    saveFormCloseImg.src = closeSvg;
    const saveForm = createElement('form', null, 'checkoutPanelForm');

    const labelSave = createElement('label', null, 'checkoutSave', { for: 'save' });
    labelSave.innerHTML = `${this.localization.strings.saveFormInputName}:`;
    const inputSave = createElement(
      'input', null, 'checkoutPanelFormInput', { type: 'text', name: 'save', required: true, placeholder: this.localization.strings.saveFormInputName }
    );

    const inputSaveSubmit = createElement('input', null, 'checkoutPanelFormSubmit', { type: 'submit' });
    saveForm.addEventListener('submit', this.onSaveFormSubmit);
    saveFormClose.onclick = () => this.setFormVisibility('checkoutSaveFormWrapper', false);

    saveFormWrapper.appendChild(saveFormHeader);
    saveFormHeader.appendChild(saveFormTitle);
    saveFormHeader.appendChild(saveFormClose);
    saveFormClose.appendChild(saveFormCloseImg);
    saveFormWrapper.appendChild(saveForm);
    saveFormWrapperBack.appendChild(saveFormWrapper);
    saveForm.appendChild(labelSave);
    saveForm.appendChild(inputSave);
    saveForm.appendChild(inputSaveSubmit);

    const loadFormWrapperBack = createElement('div', 'checkoutLoadFormWrapper', 'checkoutPanelFormWrapperBack');
    const loadFormWrapper = createElement('div', null, 'checkoutPanelFormWrapper');
    const loadFormHeader = createElement('div', null, 'checkoutPanelFormHeader');
    const loadFormTitle = createElement('p', null, 'checkoutPanelFormTitle');
    loadFormTitle.innerHTML = `${this.localization.strings.loadFormTitle}:`;
    const loadFormClose = createElement('div', 'checkoutPanelLoadFormClose', 'checkoutPanelCloseBtn');
    const loadFormCloseImg = createElement('img');
    loadFormCloseImg.src = closeSvg;
    const loadForm = createElement('form', null, 'checkoutPanelForm');

    const labelLoad = createElement('label', null, 'checkoutSave', { for: 'load' });
    labelLoad.innerHTML = `${this.localization.strings.loadFormTitle}:`;
    const inputLoadContainer = createElement('div', null, 'checkoutPanelFormSelectContainer');
    const inputLoad = createElement(
      'select', 'checkoutPanelSelectLoad', 'checkoutPanelFormSelect', { name: 'load', required: true }
    );

    const loadDefaultOption = createElement(
      'option', null, '', { value: null }
    );
    loadDefaultOption.innerHTML = this.localization.strings.loadFormDefaultOption;

    const inputLoadSubmit = createElement('input', null, 'checkoutPanelFormSubmit', { type: 'submit' });
    loadForm.addEventListener('submit', this.onLoadFormSubmit);
    loadFormClose.onclick = () => this.setFormVisibility('checkoutLoadFormWrapper', false);

    loadFormWrapper.appendChild(loadFormHeader);
    loadFormHeader.appendChild(loadFormTitle);
    loadFormHeader.appendChild(loadFormClose);
    loadFormClose.appendChild(loadFormCloseImg);
    loadFormWrapper.appendChild(loadForm);
    loadFormWrapperBack.appendChild(loadFormWrapper);
    loadForm.appendChild(labelLoad);
    inputLoadContainer.appendChild(inputLoad);
    inputLoadContainer.appendChild(selectArrowIcon.cloneNode(true));
    loadForm.appendChild(inputLoadContainer);
    inputLoad.appendChild(loadDefaultOption);
    loadForm.appendChild(inputLoadSubmit);

    const discountFormWrapperBack = createElement('div', 'checkoutDiscountFormWrapper', 'checkoutPanelFormWrapperBack');
    const discountFormWrapper = createElement('div', null, 'checkoutPanelFormWrapper');
    const discountFormHeader = createElement('div', null, 'checkoutPanelFormHeader');
    const discountFormTitle = createElement('p', null, 'checkoutPanelFormTitle');
    discountFormTitle.innerHTML = `${this.localization.strings.discountFormTitle}:`;
    const discountFormClose = createElement('div', 'checkoutPanelDiscountFormClose', 'checkoutPanelCloseBtn');
    const discountFormCloseImg = createElement('img');
    discountFormCloseImg.src = closeSvg;
    const discountForm = createElement('form', null, 'checkoutPanelForm');

    const labelDiscount = createElement('label', null, 'checkoutDiscount', { for: 'discount' });
    labelDiscount.innerHTML = `${this.localization.strings.discountFormTitle}:`;
    const inputDiscount = createElement(
      'select', 'checkoutPanelSelectDiscount', 'checkoutPanelFormInput', { name: 'discount', required: true }
    );
    const discountDefaultOption = createElement(
      'option', null, '', { value: null }
    );
    discountDefaultOption.innerHTML = this.localization.strings.discountFormDefaultOption;

    const inputDiscountSubmit = createElement('input', null, 'checkoutPanelFormSubmit', { type: 'submit' });
    discountForm.addEventListener('submit', this.onDiscountFormSubmit);
    discountFormClose.onclick = () => this.setFormVisibility('checkoutDiscountFormWrapper', false);

    discountFormWrapper.appendChild(discountFormHeader);
    discountFormHeader.appendChild(discountFormTitle);
    discountFormHeader.appendChild(discountFormClose);
    discountFormClose.appendChild(discountFormCloseImg);
    discountFormWrapper.appendChild(discountForm);
    discountFormWrapperBack.appendChild(discountFormWrapper);
    discountForm.appendChild(labelDiscount);
    discountForm.appendChild(inputDiscount);
    inputDiscount.appendChild(discountDefaultOption);
    discountForm.appendChild(inputDiscountSubmit);

    loadImgTxt.innerHTML = this.localization.strings.load;
    saveImgTxt.innerHTML = this.localization.strings.save;
    printImgTxt.innerHTML = this.localization.strings.print;
    priceImgTxt.innerHTML = this.localization.strings.priceRequest;
    checkoutImgTxt.innerHTML = this.localization.strings.checkout;
    logicSWrapper.innerHTML = 'Crafted by&nbsp<a href="https://logics.rs/portfolio/swistrax-garage-floor-designer/" target="_open">Logic Solutions</a>';
    loadImg.src = LoadIcon;
    saveImg.src = SaveIcon;
    printImg.src = PrintIcon;
    priceImg.src = PriceIcon;
    checkoutImg.src = CheckOutIcon;
    saveWrapper.appendChild(saveImg);
    loadWrapper.appendChild(loadImg);
    priceWrapper.appendChild(priceImg);
    printWrapper.appendChild(printImg);
    //checkuotWrapper.appendChild(checkoutImg);
    saveWrapper.appendChild(saveImgTxt);
    loadWrapper.appendChild(loadImgTxt);
    priceWrapper.appendChild(priceImgTxt);
    printWrapper.appendChild(printImgTxt);
    //checkuotWrapper.appendChild(checkoutImgTxt);
    wrapper.appendChild(loadSaveWrapper);
    wrapper.appendChild(pricePrintWrapper);
    //wrapper.appendChild(checkuotWrapper);
    wrapper.appendChild(logicSWrapper);
    loadSaveWrapper.appendChild(saveWrapper);
    loadSaveWrapper.appendChild(loadWrapper);
    pricePrintWrapper.appendChild(priceWrapper);
    pricePrintWrapper.appendChild(printWrapper);

    this.createClickEventHandler(loadWrapper, this.onLoadClick);
    this.createClickEventHandler(saveWrapper, this.onSaveClick);
    this.createClickEventHandler(printWrapper, this.onPrintClick);
    this.createClickEventHandler(priceWrapper, this.onPriceRequestClick);
    //this.createClickEventHandler(checkuotWrapper, this.onCheckOutClick);

    this.container.appendChild(wrapper);
    this.container.appendChild(personalDetailsWrapperBack);
    this.container.appendChild(saveFormWrapperBack);
    this.container.appendChild(loadFormWrapperBack);
    this.container.appendChild(discountFormWrapperBack);
    this.populateSelectOptions('checkoutPanelSelectDiscount', config.discountCodes);
  }

  createClickEventHandler = (el, callback) => {
    this.createEventListener(el, 'click', callback);
  }

  setFormVisibility = (formId, value) => {
    const form = document.getElementById(formId);
    value ? form.style.display = 'flex' : form.style.display = 'none';
    const firstInput = form.querySelector('input');
    firstInput?.focus();
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    const userData = {
      first_name: event.target.elements.first_name.value,
      last_name: event.target.elements.last_name.value,
      email: event.target.elements.email.value.toLowerCase(),
      state: event.target.elements.state.value,
      phone: event.target.elements.phone_code.value + event.target.elements.phone.value
    };
    this.setFormVisibility('checkoutPersnalDetailsWrapper', false);
    this.formFor === 'print' && this.onPrintClick(null, userData);
    this.formFor === 'priceRequest' && this.onPriceRequestClick(null, userData);
  }

  onDiscountFormSubmit = (event) => {
    event.preventDefault();
    this.setFormVisibility('checkoutDiscountFormWrapper', false);
    const user = JSON.parse(window.localStorage.getItem('user'));
    const discount = event.target.elements.discount.value;
    this.formFor === 'print' && this.print(user, discount);
    this.formFor === 'priceRequest' && this.priceRequest(user, discount);
    this.formFor === 'checkout' && this.openCheckoutUrl(discount);
  }

  onSaveFormSubmit = (event) => {
    event.preventDefault();
    this.setFormVisibility('checkoutSaveFormWrapper', false);
    if (getCookie('token_check') === 'valid') this.save(event.target.elements.save.value);
  }

  onLoadFormSubmit = (event) => {
    event.preventDefault();
    this.setFormVisibility('checkoutLoadFormWrapper', false);
    if (event.target.elements.load.value && getCookie('token_check') === 'valid') this.load(event.target.elements.load.value);
  }

  populateSelectOptions = (selectId, options) => {
    const selectElement = document.getElementById(selectId);
    [...selectElement.childNodes].forEach(el => el.remove());
    options.forEach(el => {
      const loadOption = createElement(
        'option', null, '', { value: el.id }
      );
      loadOption.innerHTML = el.name;
      selectElement.appendChild(loadOption);
    });
  }

  onLoadClick = () => {
    if (getCookie('token_check') === 'valid') {
      const scenes = JSON.parse(window.localStorage.getItem('scenes'));
      scenes && this.populateSelectOptions('checkoutPanelSelectLoad', scenes);
      this.setFormVisibility('checkoutLoadFormWrapper', true);
    } else if (!isLoggedIn) {
      showDrawer();
    }
  }

  onSaveClick = () => {
    if (getCookie('token_check') === 'valid') {
      this.setFormVisibility('checkoutSaveFormWrapper', true);
    } else if (!isLoggedIn) {
      showDrawer();
    }
  }

  onPrintClick = (e, user = null) => {
    this.formFor = 'print';
    if (user === null) {
      if (getCookie('token_check') === 'valid') {
        user = JSON.parse(window.localStorage.getItem('user'));
        if (user.inhouse) {
          this.setFormVisibility('checkoutDiscountFormWrapper', true);
          return;
        }
        this.print(user);
      } else if (!isLoggedIn) {
        this.setFormVisibility('checkoutPersnalDetailsWrapper', true);
        // return;
      }
    } else {
      this.print(user);
    }
  }

  onPriceRequestClick = (e, user = null) => {
    this.formFor = 'priceRequest';
    if (user === null) {
      if (getCookie('token_check') === 'valid') {
        user = JSON.parse(window.localStorage.getItem('user'));
        if (user.inhouse) {
          this.setFormVisibility('checkoutDiscountFormWrapper', true);
          return;
        }
        this.priceRequest(user);
      } else if (!isLoggedIn) {
        this.setFormVisibility('checkoutPersnalDetailsWrapper', true);
        // return;
      }
    } else {
      this.priceRequest(user);
    }
  }

  onCheckOutClick = () => {
    let user;
    if (getCookie('token_check') === 'valid') {
      user = JSON.parse(window.localStorage.getItem('user'));
      if (user.inhouse) {
        this.setFormVisibility('checkoutDiscountFormWrapper', true);
        this.formFor = 'checkout';
        return;
      }
    }
    this.openCheckoutUrl();
  }

  openCheckoutUrl = (discount = null) => {
    gtag('event', 'Checkout', { event_category: 'NEW 3-D Floor Designer' });
    const checkoutUrl = this.checkout(discount);
    window.open(checkoutUrl, '_blank');
  }
}
