import ObjectModelControls from '../ObjectModelControls';

export default class TileControlPanel extends ObjectModelControls {
  constructor (onTileChange, onTilePaternChange, onTileColorChange, deafultTile = 1, defaultPatern = 1, deafultColor = 'red', units = 'metric') {
    super();
    this.panel = null;
    this.parentElement = this.createTileWrapper();
    this.tiles = this.prepareTiles();
    this.patterns = this.preparePatterns();
    this.units = units;
    this.onTileChange = onTileChange;
    this.onTilePaternChange = onTilePaternChange;
    this.colors = this.prepareColors();
    this.vinylColors = this.prepareVinylColors();
    this.allPaletteSimple = this.config.allColorPalettePro.map(e => e.color);
  }

  prepareColors = () => {
    const colors = [];
    for (let index = 0; index < 18; index++) {
      const element = this.config.allColorPalettePro[index];
      colors.push(element);
    }
    return colors;
  }

  prepareVinylColors = () => {
    const colors = [];
    for (let index = 18; index < 25; index++) {
      const element = this.config.allColorPalettePro[index];
      colors.push(element);
    }
    return colors;
  }

  prepareTiles = () => {
    return this.config.tiles;
  }

  preparePatterns = () => {
    return this.config.tilePatterns;
  }

  createTileWrapper = () => {
    return this.createWrappingElement('div', 'tileControl');
  }

  create = (panelName, x = 0, y = 0) => {
    !this.panel && (this.panel = this.createNewPanel(panelName, x, y, this.parentElement));

    this.createTileSelectorOption();
    this.createPatternOption();
    this.addTextDesriptionForPanel();
  }

  createTileSelectorOption = (value, optionName = 'TilesSelectorControl') => {
    this.tileOptionName = optionName;
    this.panel.addTileSelector(this.tileOptionName, this.onTileValueChange, this.onPopUpShown, this.tiles, 0);
  }

  createPatternOption = (value, optionName = 'PatternControl') => {
    this.patternOptionName = optionName;
    this.panel.addPatern(this.patternOptionName, this.onPatternValueChange, this.onPopUpShown, this.patterns, 0, this.colors);
  }

  onPopUpShown = (shown) => {
    if (shown) {
      this.parentElement.style.zIndex = 100;
    } else {
      this.parentElement.style.zIndex = 10;
    }
  }

  addTextDesriptionForPanel = () => {
    const el = this.parentElement;
    const holder = el.querySelector('.qs_main');
    const textDiv = document.createElement('div');
    textDiv.id = 'tileControlTitle';
    textDiv.innerHTML = this.localization.strings.pattern;
    holder.prepend(textDiv);
  }

  onTileValueChange = (value) => {
    const configTileObject = this.config.tiles[value];
    this.setPatternColorPalette(0,
      configTileObject.colorPalette,
      configTileObject.defaultPatternColors,
      configTileObject.textureColorPalette,
      configTileObject.mapColorsToPattern);

    this.onTileChange(value);
    return value;
  }

  onPatternValueChange = (value) => {
    this.onTilePaternChange(value);
    return value;
  }

  getTileSelectorValue = (optionName = 'TilesSelectorControl') => {
    return this.getValue(optionName);
  }

  getValue = (optionName) => {
    return this.panel.getValue(optionName);
  }

  setValue = (value, optionName) => {
    this.panel.setValue(optionName, value);
  }

  setTileValue = (value) => {
    this.setValue(value, this.tileOptionName);
  }

  setPatternColorPalette = (
    patternIndex = 0,
    palette = this.allPaletteSimple,
    defaultColors = this.config.tiles[0].defaultPatternColors,
    isTexture = false,
    mapColorToPattern = false,
    patternColorNames = this.vinylColors
  ) => {
    this.panel.changePatternColorPalette(this.patternOptionName, patternIndex, palette, defaultColors, isTexture, mapColorToPattern, patternColorNames);
  }

  /**
   * Set units for
   * @param {*} units metric|imperial
   */
  setUnits = (units) => {
    this.units = units;
  }

  hide = () => {
    this.panel.hide();
  }

  show = () => {
    this.panel.show();
  }

  remove = () => {
    this.panel.destroy();
  }
}
