import { World } from './World';
import swisstraxLogo from './assets/images/icons/landing_page/pg_logo.svg';
import swisstraxFlag from './assets/images/icons/landing_page/pg_flag.svg';
import { tilePatterns } from './assets/svgPatternFiles/lpBg';
import closeSvg from './assets/images/icons/close.svg';
import { loadLoginFormHTML } from './uiComponents/login.js';
import { loadRegisterFormHTML } from './uiComponents/register.js';
import { getCookie } from './assets/helpers/cookie.js';
import { hideHeader, showHeader } from './uiComponents/header.js';
import { showDrawer, hideDrawer, handleOutsideClickDrawer } from './uiComponents/drawer.js';
import { loadUserMenu } from './uiComponents/userMenu.js';
import { getScenes } from './api/getScenes';
import { fetchPost } from './api/api';
import NoticePopup from './models/uiPopups/notice';

const LOGIN = 'LOGIN';
const REGISTER = 'REGISTER';
const USER_MENU = 'USER_MENU';

let selected = LOGIN;
export let initialized = false;

(function () {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    console.log('beforeinstallprompt event was fired.');
  });
  if ('serviceWorker' in navigator && process.env.ENV === 'prod') {
    navigator.serviceWorker.register('./service-worker.js')
      .then(reg => console.log('Service Worker registered'))
      .catch(err => console.log('Service Worker not registered', err));
  }
  load();
})();

function load () {
  getCookie('token_check') === 'valid' ||
    window.location.href.indexOf('scene_id=') > -1
    ? initEditor()
    : loadLandingPage();
}

function loadLandingPage () {
  hideHeader();
  loadContent();
  initDrawer();
}

export function removeLandingPage () {
  const landingPage = document.getElementById('lp');
  landingPage.style.display = 'none';
}

function loadContent () {
  const content = document.getElementById('lp');
  content.innerHTML = `
    <div id="lp_content">
      <div class="lp_content_column">
        <img src="${swisstraxLogo}" class="lp_content_logo" alt="logo"/>
        <div class="lp_content_label">Design your garage floor</div>
        <div id="lp_content_buttons" class="lp_content_buttons">
          <button id="lp_content_cta" class="lp_content_cta">Create Now</button>
          <div class="auth_buttons_container">
            <button id="lp_content_signin_cta" class="lp_content_auth_button">Sign In</button>
            <button id="lp_content_signup_cta" class="lp_content_auth_button">Sign Up</button>
          </div>
        </div>
      </div>
      <div class="lp_content_image_container">
        ${tilePatterns}
      </div>
    </div>
  `;

  addContentEventListeners();
}

function addContentEventListeners () {
  const createNow = document.getElementById('lp_content_cta');
  const signInButton = document.getElementById('lp_content_signin_cta');
  const signUpButton = document.getElementById('lp_content_signup_cta');
  createNow.addEventListener('click', initEditor);
  signInButton.addEventListener('click', () => showDrawerModal(LOGIN));
  signUpButton.addEventListener('click', () => showDrawerModal(REGISTER));
}

function initDrawer () {
  const drawer = document.getElementById('lp_drawer');
  drawer.innerHTML = `
    <div id="lp_drawer_header">
      <img src="${swisstraxFlag}" class="lp_drawer_flag ddd" alt="drawer_logo"/>
    </div>
  `;
  const drawerWrapper = document.getElementById('lp_drawer_wrapper');
  drawerWrapper.addEventListener('click', handleOutsideClickDrawer);

  loadFormHTML();
  loadDrawerButtons();
}

export function loadFormHTML () {
  const drawer = document.getElementById('lp_drawer');
  drawer.innerHTML += `
    <div id="lp_drawer_form_container">
      <form action="javascript:void(0);" id="lp_drawer_form"></form>
      <div id="lp_drawer_switch_forms">
        <p id="lp_drawer_switch_forms_text">Don't have an account?</p>
        <button id="lp_drawer_switch_forms_button">Sign Up</button>
      </div>
      <p id='forgotPass'>Forgot your password?</p>
    </div>
  `;

  openInitialForm();
  document.getElementById('lp_drawer_switch_forms_button').addEventListener('click', switchForms);
  document.getElementById('forgotPass').addEventListener('click', resetPass);
}

function openInitialForm () {
  const user = JSON.parse(window.localStorage.getItem('user'));
  const firstName = user && user.first_name;
  if (firstName !== null && getCookie('token_check') === 'valid') {
    loadUserMenu();
  } else {
    loadLoginFormHTML();
  }
}

export function showDrawerModal (selected) {
  showDrawer();
  setSelected(selected);
  changeHeaderButtonText();

  switch (selected) {
    case LOGIN:
      loadLoginFormHTML();
      break;
    case REGISTER:
      loadRegisterFormHTML();
      break;
    case USER_MENU:
      loadUserMenu();
      break;
  }
}

function loadDrawerButtons () {
  const drawerHeader = document.getElementById('lp_drawer_header');
  drawerHeader.innerHTML += '<button id="lp_drawer_close_button" aria-label="close drawer"></button>';
  const closeButton = document.getElementById('lp_drawer_close_button');
  closeButton.innerHTML = `<img src="${closeSvg}" alt="close img"/>`;
  closeButton.addEventListener('click', hideDrawer);
}

function changeHeaderButtonText () {
  if (selected === 'LOGIN') {
    document.getElementById('lp_drawer_switch_forms_text').innerText = 'Don\'t have an account?';
    document.getElementById('lp_drawer_switch_forms_button').innerText = 'Sign Up';
    document.getElementById('forgotPass').style = 'display: flex';
  } else if (selected === 'REGISTER') {
    document.getElementById('lp_drawer_switch_forms_text').innerText = 'Already have an account?';
    document.getElementById('lp_drawer_switch_forms_button').innerText = 'Sign In';
    document.getElementById('forgotPass').style = 'display: none';
  }
}

function resetPass () {
  const resetPassContainer = document.createElement('div');
  resetPassContainer.id = 'resetPassContainer';
  resetPassContainer.className = 'resetPassContainer';
  const resetPassForm = document.createElement('form');
  resetPassForm.className = 'resetPassForm';

  const resetPassCloseBtn = document.createElement('button');
  resetPassCloseBtn.id = 'reset_pass_close_button';
  const resetPassCloseBtnImg = document.createElement('img');
  resetPassCloseBtnImg.src = closeSvg;

  const resetPassLabel = document.createElement('label');
  resetPassLabel.innerHTML = 'Enter your email';
  resetPassLabel.setAttribute('for', 'resetPassEmail');
  const resetPassInput = document.createElement('input');
  resetPassInput.className = 'resetPassInput';
  resetPassInput.setAttribute('name', 'resetPassEmail');
  resetPassInput.setAttribute('type', 'email');
  const inputSubmit = document.createElement('button');
  inputSubmit.innerHTML = 'Reset your password';
  inputSubmit.className = 'resetPassSubmit';
  inputSubmit.setAttribute('type', 'submit');

  resetPassContainer.onclick = (event) => event.stopPropagation();
  resetPassCloseBtn.onclick = () => resetPassContainer.remove();
  resetPassForm.addEventListener('submit', onResetPassSubmit);

  document.getElementById('lp_drawer_wrapper').appendChild(resetPassContainer);
  resetPassContainer.appendChild(resetPassForm);
  resetPassForm.appendChild(resetPassCloseBtn);
  resetPassCloseBtn.appendChild(resetPassCloseBtnImg);
  resetPassForm.appendChild(resetPassLabel);
  resetPassForm.appendChild(resetPassInput);
  resetPassForm.appendChild(inputSubmit);

  resetPassInput.focus();
}

async function onResetPassSubmit (event) {
  event.preventDefault();
  try {
    World.preloader.show();
    document.getElementById('resetPassContainer').remove();
    const res = await fetchPost({ email: event.target.elements.resetPassEmail.value.toLowerCase() }, '/users/password/reset');
    await new NoticePopup(res.status === 'success'
      ? `
          <p>SUCCESS</p>
          An email with further instructions has been sent to the supplied email address.
        `
      : res.error.message
    );
  } catch (error) {
    console.log('ERROR reset pass', error);
    await new NoticePopup(
      `
        <p>ERROR</p>
        An unexpected error has occurred. Please try again later.
      `
    );
  } finally {
    World.preloader.hide();
  }
}

function switchForms () {
  removeCurrentForm();
  selected === 'LOGIN'
    ? switchForm('Sign In', REGISTER, loadRegisterFormHTML)
    : switchForm('Sign Up', LOGIN, loadLoginFormHTML);
  removeFormError();
}

function switchForm (buttonText, selected, callback) {
  setSelected(selected);
  changeHeaderButtonText();
  (selected === LOGIN || selected === USER_MENU) ? loadLoginFormHTML() : loadRegisterFormHTML();
}

function setSelected (param) {
  selected = param;
}

export function removeCurrentForm () {
  const form = document.getElementById('lp_drawer_form');
  form.innerHTML = '';
}

export function isInitialized () {
  return initialized;
}

export function removeFormError () {
  const error = document.getElementById('lp_drawer_error');
  error && error.remove();
}

export function initEditor () {
  if (!initialized) {
    removeLandingPage();
    showHeader();
    initDrawer();
    setSelected(USER_MENU);
    const world = new World(); // eslint-disable-line
    initialized = true;
    selected = LOGIN;
    getScenes();
  }
}
